import styled from "styled-components/macro";
// import heroBanner from "../../assets/images/hero-img.jpg";
import newban from "../../assets/images/hero.jpg";

export default styled.div`
  .hero-title,
  p.paragraph-white,
  .button-flex {
    color: ${({ theme }) => theme?.colors?.["0"]};
  }

  .hero-img {
    background:linear-gradient(
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.5)),url(${newban});
    min-height: 700px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }
  .hero-content {
    margin: auto;
    text-align:center;
  }
  .hero-img-services {
    width: fit-content;
    background: white;
    padding: 10px;
    border-radius: 50px;
    display: inline-flex;
    > span {
      margin: auto;
      padding-left: 10px;
      font-size: 17px;
      font-weight: 600;
    }
  }

  
  h1.hero-content {
    .headingBold {
      font-weight: 900;
      font-size: ${({ theme }) => theme?.fonts?.["6"]};
      color: ${({ theme }) => theme?.colors?.["2"]};
    }
    font-weight: normal;
    font-size: ${({ theme }) => theme?.fonts?.["6"]};
    color: ${({ theme }) => theme?.colors?.["0"]};
  }
  
`;
