import React from "react";
import Styled from "./styles";
import MainContainer from "../../common/MainContainer";
import { Grid } from "@mui/material";
import IconComponent from "../../common/Icons";
import { useSelector } from "react-redux";
import DynamicForm from "../DynamicForm";
import { EnquiryFormObjs } from "../../common/formJson/EnquiryFormObjs";
import SubHeading from "../../common/SubHeadings";
const contactData = [
  {
    icon: "BsMailbox",
    title: "Email",
    data: "swarsaptakmusicacademy@gmail.com",
  },
  {
    icon: "MdOutlinePhone",
    title: "Phone",
    data: ["Nitin Gawade : +91-9870261907","Prachi Gawade : +91-9892217712","Mandar Gawade : +91-9967670059"]
  },
  {
    icon: "MdOutlineLocationOn",
    title: "Office address",
    data: ["1. Bhandup branch : E/7, Adarsh Co.Op. H.S.G.   Soc. Kokan Nagar, J.M.Road, Bhandup(W), Mumbai-400078","2. Mulund branch : 3D/102,1st Floor, Heritage Apartment, Ganesh Gawade Rd. Mulund(W), Mumbai-400080"],
  },
  {
    icon: "MdAccessTime",
    title: "Office & enquiry timings",
    data: ["Mon to Sun, 10:00 AM to 10:00PM"],
  },
];
const Contact = () => {
  const theme = useSelector(({ config }) => config?.theme);
  return (
    <Styled>
      <MainContainer padding="0">
        <Grid container className="contact-container" id="contact">
          <SubHeading
            fpart="Contact"
            rhpart="us"
          />
          <Grid item xs={12} sm={12} md={6}>
            <div>
              {contactData?.map(({ icon, title, data }) => (
                <div className="marginTop-30px">
                  <div className="services-wrapper">
                    <div className="service-icon">
                      <IconComponent
                        iconName={icon}
                        iconColor={theme?.colors?.["0"]}
                        avtarObjs={{
                          bgColor: theme?.colors?.["2"],
                          width: "50px",
                        }}
                      />
                    </div>
                    <div className="services-text dark">
                      <strong>{title}</strong>
                      <div>{Array.isArray(data)?(
                        data?.map((content)=>(<>
                        {content}<br></br>
                        </>))
                      ):data}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <DynamicForm formObj={EnquiryFormObjs} />
          </Grid>
        </Grid>
      </MainContainer>
    </Styled>
  );
};

export default Contact;
