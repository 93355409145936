import styled from "styled-components/macro";

export default styled.div`
  .about-container {
    background-color: ${({ theme }) => theme?.colors?.["0"]};
    text-align: center;
    padding: 20px 24px 20px 24px;
    strong>span{
      display:block;
      padding: 5px;
    }
  }
  .align-center {
    text-align: center;
  }
  span.highlt-text {
    color: ${({ theme }) => theme?.colors?.["2"]};
  }
  .zero-padding{
    padding:0 !important;
  }
  .about-img {
    width: -webkit-fill-available;
    height: 560px;
    object-fit: cover;
    @media (max-width: ${({ theme }) => theme?.deviceSize?.tablet}) {
      width: -webkit-fill-available;
      height: auto;
    }
  }
  .aboutImg-text span{
    font-size:${({ theme }) => theme?.fonts?.["12"]};
  }
  .about-content{
    color:${({ theme }) => theme?.colors?.["9"]} !important;
  }
  .musicians{
    font-size:${({ theme }) => theme?.fonts?.["4"]};
  }
  .social-link{
    img{
      width:50px;
      height:auto;
    }
    a{
      padding:10px;
    }
  }
`;
