import NavBar from "./NavBar";
import Styled from "./styles";
const Header = () => {
  return (
    <Styled>
      <NavBar />
    </Styled>
  );
};

export default Header;
