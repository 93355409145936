import Styled from "./styles";
import MainContainer from "../../common/MainContainer";
// import { Grid } from "@mui/material";
// import SubHeading from "../../common/SubHeadings";
import About from "../About";

// const worksData = ["projects", "coming", "soon.."];
const Works = () => {
  return (
    <Styled >
     
      <MainContainer padding="40px 0px 30px">
      <About />
      </MainContainer>
    </Styled>
  );
};

export default Works;
