import Styled from "./styles";
import { Grid } from "@mui/material";
import SubHeading from "../../common/SubHeadings";
import aboutImg from "../../assets/images/about.jpg";
import ytimg from "../../assets/images/yt.png";
import fbimg from "../../assets/images/fb.png";
import instaimg from "../../assets/images/insta.png";

const social = [
  {
    img: fbimg,
    link: "https://www.facebook.com/profile.php?id=100063954018677",
  },
  {
    img: ytimg,
    link: "https://www.youtube.com/@SWARSAPTAKMUSICACADEMY",
  },
  {
    img: instaimg,
    link: "https://instagram.com/swar.saptakmusicacademy",
  },
];
const About = () => {
  return (
    <Styled id="about">
      <Grid container className="about-container">
        <Grid item xs={12} sm={12} md={6}>
          <img src={aboutImg} className="about-img" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          className="about-text"
          textAlign="left"
        >
          <SubHeading
            fpart="About"
            rhpart="us"
            // spart="We Always Fight For Your Justice to Win"
            align="left"
          />
          <span className="about-content">
            Greetings to everyone! With the legacy of almost 25 years, your
            beloved Swar Saptak Music Academy has now become a worldwide
            institution for delivering quality musical education to all the
            aspiring singers & musicians.{" "}
            <p>
              Swar Saptak Music Academy is a one stop music school for all those
              who are aspiring to learn Indian Classical Vocals, Bollywood
              Vocals, Piano, Guitar, Keyboard, Violin, Djembe,Drums, Tabla,
              Dholki, Dholak Flute,Harmonium,Karaoke. We have a structured
              curriculum imparted by our highly trained teachers, who ensure
              that the students enjoy their musical journey smoothly. We also
              provide batch timings and days which are suitable and convenient
              to the students, interested students can choose to join personal
              coaching or one-on-one format. In Swar Saptak Music Academy the
              students will get periodic assessments, certifications, etc.
            </p>
            Swar Saptak Music Academy has always believed and demonstrated the
            true essence of Guru-Shishya Parampara where a Guru passes on his
            knowledge of Music to his disciples by which the shishya will not
            only learn the music but will also learn to live the art and Swar
            Saptak Music Academy wishes to carry forward this legacy.
          </span>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <p>
            All our teachers are qualified musicians (Masters In Music) and are
            further professionally trained and certified by the founders of Swar
            Saptak Music Academy- <br />
            <strong className="musicians">
              <span>
                Shri. Nitin Sadashiv Gawade
                <br /> Music Director
              </span>
              <span>
                {" "}
                Mrs. Prachi Nitin Gawade (Sawant) <br />
                Sangeet Visharad
                <br /> (Gandharva Mahavidyalaya)
              </span>{" "}
              <span>
                Mr. Mandar Nitin Gawade <br />
                Advanced Certification (Grade 8)
                <br />
                (Trinity College of London)
              </span>
            </strong>{" "}
          </p>
          <p>Follow Us</p>
          <p className="social-link">
            {social?.map(({ img, link }) => (
              <a href={link}>
                <img src={img} />
              </a>
            ))}
          </p>
        </Grid>
      </Grid>
    </Styled>
  );
};

export default About;
