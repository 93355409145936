import Styled from "./styles";
import MainContainer from "../../common/MainContainer";
import { Grid } from "@mui/material";
import SubHeading from "../../common/SubHeadings";
import { useSelector } from "react-redux";
import PaperBox from "../../common/PaperBox";
import { GetUpdates } from "../../common/formJson/Autoload/GetUpdates";
import DynamicForm from "../DynamicForm";
const imgBase=`${'https://drive.google.com/uc?export=view&id='}`;
const Updates = () => {
  const updates = useSelector(
    ({ dynamicForms }) => dynamicForms?.GetUpdates?.results?.data
  );
  console.log(updates)
  return (
    <Styled id="updates">
      <DynamicForm
        autoload={GetUpdates?.formInp[0]?.inp?.service?.Autoload}
        formObj={GetUpdates}
      />
      <MainContainer padding="0px 30px 50px 30px">
        <Grid container className="services-container" >
          <SubHeading rhpart="Updates" />
          {(updates?.length<1||!updates) && <div className="no-updates">No new updates!</div>}
          {updates?.length>0&&updates?.map((obj, index) => (
            <Grid item xs={12} sm={6} md={4}  className="service-grids">
              <PaperBox>
                <div className="fun-facts-wrapper">
                  <div className="text-content">
                  <img src={`${imgBase}${obj?.image}`} className="updates-img" />
                    <h1 className="fun-number">
                      {obj?.title}
                      <br></br>
                    </h1>
                    <p className="paragraph-white"> {obj?.description}</p>
                  </div>
                </div>
              </PaperBox>
            </Grid>
          ))}
        </Grid>
      </MainContainer>
    </Styled>
  );
};

export default Updates;
