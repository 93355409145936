import Styled from "./styles";

const SubHeading = ({ fpart, spart,rhpart=false,sColSwap=0,align }) => {
  return (
    <Styled sColSwap={sColSwap} align={align}>
        <div className="sub-heading">
          <h4 className="sub-text">{fpart} <span className="hsub-text">{rhpart}</span></h4>
          <hr className="short-divider "></hr>
          {spart&&(<h3 className="second-heading">{spart}</h3>)}
          <div className="padding-bottom50px" ></div>
        </div>
    </Styled>
  );
};

export default SubHeading;
