const initialState = {
  playWith:null,
  score:{
    X:0,
    O:0
  }
};

const manageGameData = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_PLAYWITH":
      return { ...state, playWith: action.playWith };
    case "RESET_GAME":
      return initialState;
    case "GET_OVERALL_SCORE":
      const score={...state?.score};
       score[action?.scoreData]=score[action?.scoreData]+1;
      return { ...state, score: score};
    default:
      return state;
  }
};

export default manageGameData;
