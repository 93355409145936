import * as React from "react";
import { Grid } from "@mui/material";
import Styled from "./styles";
import { HashLink } from "react-router-hash-link";

const HeroBanner = () => {
  return (
    <Styled id="home">
      <Grid container className="hero-banner-container">
        <Grid item xs={12} sm={12} md={12} className="hero-img">
          <h1 className="hero-content">
            <span className="headingBold">Swar Saptak Music Academy</span>{" "}
            <br></br>
            <span> Music Never Ends</span>
            <hr className="short-divider"></hr>
           
            <Grid container className="hero-banner-container paddingTop-30px">
              <Grid item xs={12} sm={12} md={6}>
                <HashLink smooth className="s-button" to={"/#about"}>
                 Learn more
                </HashLink>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <a className="button p-button" href="tel:9967670059">Call now</a>
              </Grid>
            </Grid>
          </h1>
        </Grid>
      </Grid>
    </Styled>
  );
};

export default HeroBanner;
