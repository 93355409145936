import React from "react";
import Styled from "./styles";
import { Grid } from "@mui/material";
import Admin from "../../Admin";

const Dashboard = () => {
  return (
    <Admin title="Dashboard">
      <Styled>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <h1>Welcome Admin</h1>
            </Grid>
          </Grid>
      </Styled>
    </Admin>
  );
};

export default Dashboard;
