import styled from 'styled-components/macro';

export default styled.div`
display:flex;
.cols{
    border:3px solid black;
    width:100px;
    height:100px;
    display:inline-flex;
}
div.content{
    margin:auto;
    font-size:50px;
}
`