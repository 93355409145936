import React,{useState} from "react";
import Styled from "./styles";
import { Grid } from "@mui/material";
import Admin from "..";
import DynamicForm from "../../DynamicForm";
import { GetUpdates } from "../../../common/formJson/Autoload/GetUpdates";
import { AddAndUpdateUpdates } from "../../../common/formJson/Admin/AddAndUpdateUpdates";
import { useSelector } from "react-redux";
import DataTable from "../../../common/DataTable";
import axios from "axios";
const columns = ["ID", "Title", "Description", "Image", "Action"];

const AddWorks = () => {
  const [editRecord,setEditRecord]=useState();
  const updates = useSelector(
    ({ dynamicForms }) => dynamicForms?.GetUpdates?.results?.data
  );
  const onClickRedirect = async (id, actionCode) => {
    if (actionCode === "d") {
      const deleteData=updates?.find((val)=>{
        return val?.id===id;
  })
      const formData = { id: id, type: "sv_updates",imgid:deleteData?.image };
      const headers = {
        params: formData,
      };
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `api/delete_record`,
        headers
      );
      if (response) {
        alert("record deleted!");
        window.location.reload();
      }
    }else if(actionCode==='e'){
      const editData=updates?.find((val)=>{
            return val?.id===id;
      })
      setEditRecord(editData);
    }
  };
  console.log(updates,'updates')
  return (
    <Admin title="Add Updates">
      <Styled>
        <DynamicForm
          autoload={GetUpdates?.formInp[0]?.inp?.service?.Autoload}
          formObj={GetUpdates}
        />
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <DynamicForm formObj={AddAndUpdateUpdates} editRecord={editRecord} />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <DataTable
              dataRows={updates}
              headRows={columns}
              onClick={onClickRedirect}
            />
          </Grid>
        </Grid>
      </Styled>
    </Admin>
  );
};

export default AddWorks;
