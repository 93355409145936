import Styled from "./styles";
import MainContainer from "../../common/MainContainer";
import { Grid } from "@mui/material";
import Divider from "../../common/Divider";
import { useSelector } from "react-redux";
import IconComponent from "../../common/Icons";
import { getCurrentDate } from "../../utils/Utils";
import whatsapp from '../../assets/images/whatsapp.png';
const Footer = () => {
  const theme = useSelector(({ config }) => config?.theme);
  const socialLinks = [
    {
      icon: "FaFacebook",
      link:"https://www.facebook.com/profile.php?id=100063954018677"
    },
    {
      icon: "FaYoutube",
      link:"https://www.youtube.com/@SWARSAPTAKMUSICACADEMY"
    },
    {
      icon: "FaInstagram",
      link:"https://instagram.com/swar.saptakmusicacademy"
    },
  ];

  return (
    <Styled>
      <MainContainer padding="0">
        <Grid container className="footer-container">
          <Grid item xs={12} sm={12}>
            <Divider />
            <Grid container className="copyright-container">
              <Grid xs={12} sm={4} className="copyr-1">
                Copyright Swar Saptak Music Academy@{getCurrentDate()}
              </Grid>
              <Grid xs={12} sm={4} className="copyr-2">
                <span>Designed & Developed By</span>
                <div className="nav-link-container">
                  <a
                    href="https://sandeepvishwakarma.onrender.com/"
                    className="nav-links"
                    target="_blank"
                  >
                    Sandeep Vishwakarma
                  </a>
                  <div className="highlight-nav"></div>
                </div>
              </Grid>
              <Grid xs={12} sm={4} className="copyr-3">
                <div className="footer-links">
                  {socialLinks?.map(({ icon,link }) => {
                    return (
                      <a href={link} className="social-link" target={"_blank"}>
                        <IconComponent
                          iconName={icon}
                          iconColor={theme?.colors?.["2"]}
                          iconFontSize={theme?.fonts?.["3"]}
                        />
                      </a>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainContainer>
      <a href="https://wa.me/9967670059" title="chat on whatsapp" ><img src={whatsapp} className="whatsapp-icon"/> </a>
    </Styled>
  );
};

export default Footer;
