import React from "react";
import Header from "../site/Header";
import Styled from "./styles";
import HeroBanner from "./HeroBanner";
import Works from "./Works";
import Updates from "./Updates";
import Contact from "./Contact";
import GetDirections from "./GetDirections";
import Footer from "./Footer";
import Instruments from "./Instruments";

const Site = () => {
  return (
    <Styled>
      <Header />
      <HeroBanner />
      <Works />
      <Updates />
      <GetDirections />
      <Instruments />
      <Contact />
      <Footer/>
    </Styled>
  );
};

export default Site;
