import React from "react";
import Styled from "./styles";
import { Grid } from "@mui/material";
import InputFields from "../../common/InputFields";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import action from "../../redux/action";
import AlertComponent from "../../common/AlertComponent";


const DynamicForm = ({formObj,autoload=false,editRecord=false}) => {
  const dispatch = useDispatch();
  const dynamicForms = useSelector(({ dynamicForms }) => dynamicForms);
  const {formId,formInp}=formObj;
  useEffect(() => {
    if ((!dynamicForms?.[formId] && formId)||editRecord) {  
      const inputObj = {};
      const fieldsObj = {};
      const validationPassed = {};
      formInp?.map(({ inp }) => {
        if (inp?.type !== "submit"&&inp?.type !== "alert") {
          fieldsObj[inp.name] = inp;
          inputObj[inp.name] = inp?.value||editRecord?.[inp.name];
          validationPassed[inp.name] = editRecord?true:(inp.required?false:true);
        }
      });
      dispatch(
        action.InitailizeDynamicForm(
          fieldsObj,
          formId,
          inputObj,
          validationPassed
        )
      );
    }
  }, [editRecord]);

  return (
    <Styled style={{display:autoload?'none':'block'}}>
      <Grid container>
        {formInp?.map((inpObjs) => {
          return (
            <Grid item xs={12} sm={inpObjs?.grid}>
              {inpObjs?.inp?.type === "alert" ? (
                <AlertComponent formId={formId} {...inpObjs?.inp} />
              ) : (
                <InputFields {...inpObjs?.inp} formId={formId} editRecordId={editRecord?.id}/>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Styled>
  );
};

export default DynamicForm;
