import * as React from "react";
import Styled from "./styles";

const Divider = () => {
  return (
    <Styled>
        
    </Styled>
  );
};

export default Divider;
