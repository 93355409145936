import manageGameData from "./manageGameData";
import config from "./config";
import dynamicForms from "./dynamicForms";
import {combineReducers} from 'redux'

const rootReducers=combineReducers({
    manageGameData,
    config,
    dynamicForms
})

export default rootReducers;