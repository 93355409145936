import styled from "styled-components/macro";

export default styled.div`
  .services-container {
    background-color: ${({ theme }) => theme?.colors?.["0"]};
    text-align: center;
    padding: 0 20px 40px 20px;
    color: ${({ theme }) => theme?.colors?.["1"]};
  }
  .no-updates{
    font-size:${({ theme }) => theme?.fonts?.["4"]};
    text-align:center;
    width:100%;
  }
  .work-ele a {
    position: relative;
    overflow: hidden;
    float: left;
  }
  .work-ele a div {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
  }
  h1 {
    font-size: ${({ theme }) => theme?.fonts?.["3"]} !important;
  }
  .service-grids{
   padding:0 10px;
   p.paragraph-white{
    word-wrap: break-word;
    padding: 0 10px;
    object-fit: contain;
    width: 100%;
    min-height: 100px;
   }
  &:hover{
    border:2px solid ${({ theme }) => theme?.colors?.["10"]};
    background-color: ${({ theme }) => theme?.colors?.["0"]};
    border-radius:10px;
  }
}
  .service-grids:hover .MuiAvatar-root {
    transition: 0.6s;
    transform: scale(0.80);
    background-color:${({ theme }) => theme?.colors?.["2"]};
    svg{
      color:${({ theme }) => theme?.colors?.["0"]};
    }
  }
  .updates-img{
    width:100%;
    height:250px;
    object-fit:contain;
  }
  
`;
