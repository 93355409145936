import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Styled from "./styles";

export default function Loader({children}) {
  return (
    <Styled>
      <Box sx={{ display: "flex" }}>
        <CircularProgress /> {children}
      </Box>
    </Styled>
  );
}
