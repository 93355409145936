import styled from "styled-components/macro";

import { keyframes } from 'styled-components';
const moveInLeft =keyframes`
0% {
opacity: 0;
transform: translateX(-10rem);
}

100% {
opacity: 1;
transform: translate(0);
}
`
const moveInRight =keyframes`
0% {
opacity: 0;
transform: translateX(10rem);
}

100% {
opacity: 1;
transform: translate(0);
}
`
export default styled.div`
  .play-with-buttons {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;class=
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    > button {
      height: 60px;
      border: 2px solid;
    }
  }
  .MuiGrid-item {
    padding: 24px;
}
@media (max-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
  .MuiContainer-root{
    padding: 0;
  }
  .MuiGrid-item {
    padding: 24px 0;
}
  
}
.MuiContainer-root{
  padding: 0 24px;
}
h1{
  font-family: 'Philosopher', sans-serif !important;
}
  .sub-text {
    color: ${({ theme }) => theme?.colors?.["2"]};
  }
  .second-heading {
    font-size: ${({ theme }) => theme?.fonts?.["7"]};
    line-height: 1;
    font-weight: 600;
    margin:0 0 40px 0 !important;
    font-family: 'Philosopher', sans-serif;
}
  .global-btn{
    color:${({ theme }) => theme?.colors?.["1"]};
    font-weight:bold;
    background-color:${({ theme }) => theme?.colors?.["0"]} !important;
    text-transform:capitalize;
    font-size:16px;
  }
  .margin-40px{
    margin-top:40px;
  }
  .marginTop-30px {
    margin-top: 30px;
  }
  .marginTop-50px {
    margin-top: 50px;
  }
  .marginTop-20px {
    margin-top: 20px;
  }
  .marginTop-10px {
    margin-top: 10px;
  }
  .padding-40px{
    padding-top:40px;
  }
  .paddingTop-30px {
    padding-top: 30px;
  }
  .paddingTop-50px {
    padding-top: 50px;
  }
  .paddingTop-20px {
    padding-top: 20px;
  }
  .paddingTop-10px {
    padding-top: 10px;
  }
  .animate-left{
    animation: ${moveInLeft} 1.3s ease-in-out .3s both;
  }
  .animate-right{
    animation: ${moveInRight} 1.3s ease-in-out .3s both;
  }
  .w-button {
    display: inline-block;

    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
  }
  .button-flex {
    display: flex;
    align-items: center;
  }
  .button.green {
    border-color: ${({ theme }) => theme?.colors?.["3"]};
    background-color: ${({ theme }) => theme?.colors?.["3"]};
  }
  .button {
    padding: 15px 40px;
    border: 2px solid ${({ theme }) => theme?.colors?.["2"]};
    background-color: transparent;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    color: ${({ theme }) => theme?.colors?.["2"]};
    font-size: 16px;
    text-transform: none;
    &:hover{
        color: ${({ theme }) => theme?.colors?.["0"]};
        background-color: ${({ theme }) => theme?.colors?.["2"]};
      
    }
  }
  .p-button {
    padding: 15px 60px;
    background-color:${({ theme }) => theme?.colors?.["10"]};
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    font-size: 16px;
    text-transform: none;
    text-decoration:none;
    border: 2px solid ${({ theme }) => theme?.colors?.["2"]};
    color: ${({ theme }) => theme?.colors?.["2"]};
    &:hover{
      border: 2px solid ${({ theme }) => theme?.colors?.["2"]};
      background-color: ${({ theme }) => theme?.colors?.["2"]};
      color: ${({ theme }) => theme?.colors?.["0"]};
    }
  }
  .s-button {
    padding: 15px 60px;
    background-color:${({ theme }) => theme?.colors?.["2"]};
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    font-size: 16px;
    text-transform: none;
    text-decoration:none;
    border: 2px solid ${({ theme }) => theme?.colors?.["2"]};
    color: ${({ theme }) => theme?.colors?.["0"]};
    &:hover{
      border: 2px solid ${({ theme }) => theme?.colors?.["0"]};
      background-color: ${({ theme }) => theme?.colors?.["1"]};
      color: ${({ theme }) => theme?.colors?.["2"]};
    }
  }
  .short-divider {
    width:120px;
    border: 1px solid ${({ theme }) => theme?.colors?.["2"]};
  }
`;
