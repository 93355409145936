const inValidErr = "Invalid input";
export const EnquiryFormObjs = {
  formId: "enquiryForm",
  formInp: [
    {
      inp: {
        InputLabelProps: {
          shrink: true,
        },
        type: "text",
        label: "Full Name",
        name: "fullName",
        id: "fullName",
        fullWidth: true,
        required: true,
        helperText: inValidErr,
        maxLength: 50,
      },
      grid: 12,
    },

    {
      inp: {
        InputLabelProps: {
          shrink: true,
        },
        type: "text",
        label: "Phone Number",
        name: "phone",
        id: "phone",
        fullWidth: true,
        required: true,
        regEx: /^\d{10}$/,
        helperText: inValidErr,
        maxLength: 10,
      },
      grid: 12,
    },

    {
      inp: {
        InputLabelProps: {
          shrink: true,
        },
        type: "text",
        label: "Message",
        name: "message",
        id: "message",
        rows: 6,
        multiline: true,
        fullWidth: true,
        helperText: inValidErr,
      },
      grid: 12,
    },
    {
      inp: {
        type: "alert",
      },
      grid: 12,
    },
    {
      inp: {
        type: "submit",
        label: "Submit!",
        loaderLabel: "Sending enquiry...",
        name: "enquiry_submit",
        id: "enquiry_submit",
        className: "btn-std",
        service: {
          emailService: true,
          url: process.env.REACT_APP_API_URL + `api/add_data`,
          method: "POST",
          params: { type: "sv_enquiries" },
        },
      },
      grid: 12,
    },
  ],
};
