import emailjs from "@emailjs/browser";
import axios from 'axios';

export function sendEmail(formDataObj,formID) {
  return emailjs
    .send(
      "service_ntpje9m",
      "template_9u89opk",
       formDataObj,
      "5iOO8fMpGybc1Vptv"
    )
}
export function dynamicFormService(formDataObj,method,url,formID,formParams=false) {
  if(method==="GET"){
    const headers={
      params:formParams
    }
     const response=axios.get(url,headers);
     return response
  }
  let newFormDataObjs=formDataObj;
  if(formParams){
    newFormDataObjs=Object.assign(formDataObj, formParams)
  }
  if(method==="POST"){
    let formData=new FormData();
       for(let key in newFormDataObjs){
        formData.append(key,newFormDataObjs?.[key]);
       }
    const response=axios.post(url,formData);
    return response
  }
}
