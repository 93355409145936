import styled from "styled-components/macro";

export default styled.div`
  .works-container {
    background-color: ${({ theme }) => theme?.colors?.["0"]};
    text-align: center;
    padding: 20px 24px 50px 24px;
  }
  .align-center {
    text-align: center;
  }
  .work-ele a {
    position: relative;
    overflow: hidden;
    float: left;
  }
  .work-ele a div {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
  }
  
  @media (max-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
    .triangle-up {
      top: 6px !important;
    }
  }
  .triangle-up {
    position: absolute;
    top: 45px;
    left: 48%;
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 35px solid ${({ theme }) => theme?.colors?.["0"]};
  } 
 
 
  .work-ele img {
    width: 100%;
  }
`;
