import styled from "styled-components/macro";

export default styled.div`
  width: 100%;
  .sub-heading {
    text-align: ${({ align }) => (align ? align : "center")};
  }
  .sub-heading .sub-text {
    color: ${({ theme }) => theme?.colors?.["2"]};
    font-size: ${({ theme }) => theme?.fonts?.["6"]};
    margin: 10px 0 15px 0;
    font-weight: 700;
    .hsub-text{
      color: ${({ sColSwap, theme }) =>
      theme?.colors?.[sColSwap ? "0" : "1"]} !important;;
    }
  }
  .padding-bottom50px{
    padding-bottom: 50px;
  }
 
  .short-divider {
    border: 1px solid ${({ theme }) => theme?.colors?.["2"]};
    ${({ align }) => (align==='left' ? 'margin-left:0': "")};
  }
  .sub-heading .second-heading {
    color: ${({ sColSwap, theme }) =>
      theme?.colors?.[sColSwap ? "1" : "0"]} !important;
    font-size: ${({ theme }) => theme?.fonts?.["3"]};
    line-height: 1.5;
    font-weight: 700;
  }
`;
