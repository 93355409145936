import styled from "styled-components/macro";

export default styled.div`
 
    padding: 0 24px;
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
    background-color: #ededed;
  
`;
