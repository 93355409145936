import React, { useState, useEffect } from "react";
import Styled from "./styles";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import action from "../../../redux/action";

const TextFieldInput = ({
  helperText,
  formId,
  name,
  regEx,
  maxLength = false,
  InputLabelProps = {},
  ...props
}) => {
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const dynamicForms = useSelector(
    ({ dynamicForms }) => dynamicForms?.[formId]
  );
  const onChangeHandler = (e) => {
    dispatch(action.passedFormValidation(false, e.target.name, formId));
    let errorFlag = false;
    dispatch(
      action.updateDynamicFormFields(e.target.name, e.target.value, formId)
    );
    if (dynamicForms?.fieldsObj?.[name]?.required && !e.target.value) {
      errorFlag = true;
    } else if (e.target.value&&
      dynamicForms?.fieldsObj?.[name]?.regEx &&
      !dynamicForms?.fieldsObj?.[name]?.regEx.test(e.target.value)
    ) {
      errorFlag = true;
    } else {
      errorFlag = false;
    }
    setError(errorFlag);
    if (!errorFlag) {
      dispatch(action.passedFormValidation(!error, e.target.name, formId));
    }
  };

  useEffect(() => {
    if (dynamicForms?.submit === true) {
      const e = {};
      e.target = {
        name: dynamicForms?.fieldsObj?.[name].name,
        value: dynamicForms?.inputValObjs?.[name],
      };
      onChangeHandler(e);
    }
  }, [dynamicForms?.submit]);
  return (
    <Styled>
      <div>
        <TextField
          error={error}
          variant="outlined"
          onChange={onChangeHandler}
          onBlur={onChangeHandler}
          helperText={error ? helperText : false}
          name={name}
          InputLabelProps={{ ...InputLabelProps }}
          value={dynamicForms?.inputValObjs?.[name]}
          inputProps={{ maxLength: maxLength }}
          {...props}
        />
      </div>
    </Styled>
  );
};

export default TextFieldInput;
