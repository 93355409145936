import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Styled from "./styles";

export default function PaperBox({children,elevation=3,width='100%',height= '100%'}) {
  return (
    <Styled>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: width,
            height: height,
          },
        }}
      >
        <Paper className="paper-div" elevation={elevation}>{children}</Paper>
      </Box>
    </Styled>
  );
}
