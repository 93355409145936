import * as React from "react";
import Styled from "./styles";
import { Avatar } from "@mui/material";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import * as IoIcons from "react-icons/io5";
import * as TiIcons from "react-icons/ti";
import * as BsIcons from "react-icons/bs";
import * as FiIcons from "react-icons/fi";
import * as CgIcons from "react-icons/cg";
import * as AiIcons from "react-icons/ai";
import * as ImIcons from "react-icons/im";
import * as GoIcons from "react-icons/go";
import * as GiIcons from "react-icons/gi";

const Icons = {
  md: MdIcons,
  fa: FaIcons,
  io: IoIcons,
  ti: TiIcons,
  bs: BsIcons,
  fi: FiIcons,
  ai: AiIcons,
  cg: CgIcons,
  im: ImIcons,
  go: GoIcons,
  gi: GiIcons
};

const IconComponent = ({
  iconName,
  iconColor = false,
  iconFontSize = false,
  avtarObjs = false,
  style = false,
  ...props
}) => {
  const iconType = iconName?.substring(0, 2).toLowerCase();
  const iconLibrary = Icons[iconType];
  const iconComponent = iconLibrary[iconName];

  return (
    <Styled
      className="icon-style"
      iconColor={iconColor}
      iconSize={iconFontSize}
      avtarObjs={avtarObjs}
    >
      {avtarObjs ? (
        <Avatar >{iconComponent(props)}</Avatar>
      ) : (
        iconComponent(props)
      )}
    </Styled>
  );
};
export default IconComponent;
