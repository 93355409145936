export const playWith = (playWith) => {
    return { type: "UPDATE_PLAYWITH",playWith };
  };
  export const resetGame = () => {
    return { type: "RESET_GAME"};
  };
  export const score = (scoreData) => {
    return { type: "GET_OVERALL_SCORE",scoreData};
  };

  
