const initialState = {};

const dynamicForms = (state = initialState, action) => {

  const resetForm=(formID)=>{
    let keysObjs = Object.keys(state?.[formID]?.validationPassed);
          let valObjs={};
          let validObj={};
         keysObjs?.map((keys)=>{
              valObjs[keys]="";
              validObj[keys]=false;
         })
         return {valObjs,validObj};
  }
  switch (action.type) {
    case "SET_DF":
      return {
        ...state,
          [action?.formID]: {
            submit:false,
            validationPassed:action?.validationPassed,
            fieldsObj: action?.fieldsObj,
            inputValObjs: action?.inputValObjs,
            results:null,
            loadingData:false
        },
      };
    case "UPDATE_DFF":
      const inputValObjs = state?.[action?.formID]?.inputValObjs;
      return {
        ...state,
        [action?.formID]: {
          ...state?.[action?.formID],
          submit:false,
          inputValObjs: {
            ...inputValObjs,
            [action?.fieldName]: action.value,
          },
        },
      };
      case "SUBMIT_FLAG":
      return {
        ...state,
        [action?.formID]: {
          ...state?.[action?.formID],
          submit:true
        },
      };
      case "PASSED_FORMVALIDATION":
        const validationPassedObj = state?.[action?.formID]?.validationPassed;
        return {
          ...state,
          [action?.formID]: {
            ...state?.[action?.formID],
            validationPassed:{...validationPassedObj,[action?.fieldName]:action.value}
          },
        };
        case "RESET_DF":
          const getResData=resetForm(action?.formID);
        return {
          ...state,
          [action?.formID]: {
            ...state?.[action?.formID],
            inputValObjs:getResData?.valObjs,
            validationPassed:getResData?.validObj,
            results: null
          },
        };
        case "DYNAMICFORM_SC":
          const getResDataAfterCall=resetForm(action?.formID);
        return {
          ...state,
          [action?.formID]: {
            ...state?.[action?.formID],
            inputValObjs:getResDataAfterCall?.valObjs,
            validationPassed:getResDataAfterCall?.validObj,
            loadingData:false,
            results: action.result
          },
        };
        case "LOADING_DATA":
          return {
            ...state,
            [action?.formID]: {
              ...state?.[action?.formID],
              loadingData:true,
            },
          };

    default:
      return state;
  }
};

export default dynamicForms;
