import React, { useState, useEffect } from "react";
import "./App.css";
import Site from "./site";
import Login from "./site/Login";
import AddUpdates from "./site/Admin/AddUpdates";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import action from "./redux/action";
import Config from "./Config";
import { ThemeProvider } from "styled-components";
import Dashboard from "./site/Admin/Dashboard";
import Enquiries from "./site/Admin/Enquiries";
function App() {
  const [theme, setTheme] = useState("0");
  const dispatch = useDispatch();
  const globalTheme=useSelector(({config})=>config?.theme)

  useEffect(() => {
    if (theme) {
      setTheme(Config?.Theme[theme]);
      dispatch(action.updateTheme(Config?.Theme[theme]));
    }
  }, []);
  return (
    <Router>
      <ThemeProvider theme={globalTheme?globalTheme:{}}>
      <Routes>
        <Route exact path="/" element={<Site />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/admin/add-updates" element={<AddUpdates />} />
        <Route exact path="/admin/dashboard" element={<Dashboard />} />
        <Route exact path="/admin/enquiries" element={<Enquiries />} />
      </Routes> 
      </ThemeProvider>
    </Router>
  );
}

export default App;
