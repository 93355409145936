import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import Styled from "./styles";
import { useDispatch, useSelector } from "react-redux";
import action from "../../../redux/action";

const RichTextEditorInput = ({
  formId,
  name,
  helperText,
  required,
  placeholder = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const dynamicForms = useSelector(
    ({ dynamicForms }) => dynamicForms?.[formId]
  );
  const editor = useRef(null);
  const onChange = (content) => {
    if(content?.name){
      name=content?.name;
      content=content?.value;
    }
    dispatch(action.passedFormValidation(false, name, formId));
    let errorFlag = false;
    dispatch(action.updateDynamicFormFields(name, content, formId));
    if (required && !content?.length) {
      errorFlag = true;
    } else if (
      dynamicForms?.fieldsObj?.[name]?.regEx &&
      !dynamicForms?.fieldsObj?.[name]?.regEx.test(content)
    ) {
      errorFlag = true;
    } else {
      errorFlag = false;
    }
    setError(errorFlag);
    if (!errorFlag) {
      dispatch(action.passedFormValidation(!errorFlag, name, formId));
    }
  };
  useEffect(() => {
    if (dynamicForms?.submit === true) {
      const data = {
        name: dynamicForms?.fieldsObj?.[name].name,
        value: dynamicForms?.inputValObjs?.[name],
      };
      onChange(data);
    }
  }, [dynamicForms?.submit]);
  return (
    <Styled>
      <JoditEditor
        ref={editor}
        value={dynamicForms?.inputValObjs?.[name]}
        onChange={(newContent) => onChange(newContent)}
        {...props}
      />
      {error && <span className="error">{helperText}</span>}
    </Styled>
  );
};

export default RichTextEditorInput;
