import styled from "styled-components/macro";

export default styled.div`
  background-color: ${({ theme }) => theme?.colors?.["1"]};
  .footer-container {
    padding: 0px 15px 0px 15px;
    background-color: ${({ theme }) => theme?.colors?.["0"]};
  }
  @media (max-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
    .copyright-container .MuiGrid-root {
      padding: 10px 0 !important;
      text-align: center;
    }
  }
 
  .copyr-1 {
    text-align: left;
  }
  .copyr-2 {
    text-align: center;
  }
  .copyr-3 {
    text-align: right;
  }
  .footer-links {
    display: inline-flex;
    > a {
      padding: 0 5px;
    }
  }
  .nav-link-container{
    display:contents;
  }
  .nav-links {
    color: ${({ theme }) => theme?.colors?.["1"]};
    font-weight: bold;
    background-color: ${({ theme }) => theme?.colors?.["0"]};
    font-size: ${({ theme }) => theme?.fonts?.["1"]};
    padding-left:5px;
  }
  .nav-links: hover {
    transition:0.5s;
    text-decoration:none;
    color: ${({ theme }) => theme?.colors?.["2"]};
  }
  img.whatsapp-icon{
    position: fixed;
    width: 60px;
    bottom:40px;
    right:20px;
  }
`;
