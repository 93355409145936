import styled from "styled-components/macro";

export default styled.div`
background-color: ${({ theme }) => theme?.colors?.["1"]};
  .contact-container {
    padding: 50px 15px 30px 15px;
    background-color: ${({ theme }) => theme?.colors?.["0"]};
  }
  .services-wrapper {
    display: inline-flex;
    position: relative;
    left: 0%;
    bottom: 0%;
    padding: 0px;
    border-radius: 0px;
    top: auto;
    right: auto;
  }
  .services-text {
    margin: auto;
    padding-left: 15px;
    display:inline-block;
    >div{
      color:${({ theme }) => theme?.colors?.["9"]};
    }
  }
  .services-text a{
    padding-left:10px;
  }
  // h3{
  //   color:${({ theme }) => theme?.colors?.["9"]};
  // }
`;
