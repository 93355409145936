import styled from "styled-components/macro";

export default styled.div`
 
    width: 100%;
    height: 100%;
    iframe{
        width:100%;
        height:400px;
    }
  
`;
