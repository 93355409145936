import styled from "styled-components/macro";
import bgimage from "../../assets/images/service.jpg";

export default styled.div`
background:linear-gradient(
  rgba(0, 0, 0, 0.75),
  rgba(0, 0, 0, 0.9)),url(${bgimage});
min-height: 620px;
background-attachment: fixed;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
  .services-container {
    background-color: ${({ theme }) => theme?.colors?.["8"]};
    text-align: center;
    padding: 50px 20px 30px 20px;
    color: ${({ theme }) => theme?.colors?.["0"]};
    max-width:1235px;
    margin:auto;
    @media (max-width: ${({ theme }) => theme?.deviceSize?.tablet}) {
      padding: 50px 20px;
      max-width:100%;
    }
  }
  .icon-style{
    padding-top:20px;
  }
}
// .fun-facts-wrapper{
//   background-color: ${({ theme }) => theme?.colors?.["1"]};
//   opacity: 0.6;
//   padding:0 10px;
// }
.service-grids:hover{
  background-color: ${({ theme }) => theme?.colors?.["10"]};
  color:${({ theme }) => theme?.colors?.["0"]};
  border-radius:10px;
  transition: 0.3s;
}
.service-grids:hover .MuiAvatar-root {
  transition: 0.3s;
  box-shadow: 0 0 10px 5px #48abe0,
  0 0 20px 7px ${({ theme }) => theme?.colors?.["2"]},
  0 0 3px 2px ${({ theme }) => theme?.colors?.["1"]},
  0 0 3px 2px ${({ theme }) => theme?.colors?.["9"]};
  transform: scale(0.80);
  background-color:${({ theme }) => theme?.colors?.["2"]};
  svg{
    color:${({ theme }) => theme?.colors?.["0"]};
  }
}
h1 {
  font-size: ${({ theme }) => theme?.fonts?.["3"]} !important;
}

`;
