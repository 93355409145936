import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { HashLink } from "react-router-hash-link";
import Styled from "./styles";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import logo from "../../../assets/images/logo/logo-head.png";

const drawerWidth = "80%";
const navItems = [
  { title: "Home", path: "/#home" },
  { title: "About us", path: "/#about" },
  { title: "Updates", path: "/#updates" },
  { title: "Get directions", path: "/#directions" },
  { title: "Instruments", path: "/#instruments" },
  { title: "Contact", path: "/#contact" },
];


function NavBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const logoImg = () => {
    return <img src={logo} className="logo" />;
  };
  const drawer = (
    <Styled>
      <Box
        onClick={handleDrawerToggle}
        sx={{ textAlign: "center" }}
        className="menu-drawer"
      >
        <Typography
          variant="h6"
          component="div"
          className="logo-icon"
          sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
        >
          {logoImg()}
        </Typography>
        <Divider />
        <List>
          {navItems.map(({ title, path }, index) => (
            <ListItem key={title} disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <HashLink
                  smooth
                  to={path}
                  key={index}
                  sx={{ color: "#fff" }}
                  className="nav-links"
                >
                  {title}
                </HashLink>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Enquiry /> */}
      </Box>
    </Styled>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const trigger = useScrollTrigger({ disableHysteresis: true });
  return (
    <Styled>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          component="nav"
          className={`nav-bar ${trigger ? "scroll-nav" : "top-nav"}`}
        >
          <div className="container">
          <Toolbar className="navbar-height">
            <Typography
              variant="h6"
              component="div"
              className="logo-icon"
              sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
            >
              {logoImg()}
            </Typography>

            <Box
              sx={{ display: { xs: "none", sm: "none", md: "block" } }}
              className="desk-nav-links"
            >
              {navItems?.map(({ title, path }, index) => (
                <div className="nav-link-container">
                  <HashLink
                    smooth
                    to={path}
                    key={index}
                    sx={{ color: "#fff" }}
                    className="nav-links"
                  >
                    {title}
                  </HashLink>
                  <div className="highlight-nav"></div>
                </div>
              ))}
              {/* <div className="enq-div"><Enquiry /></div> */}
            </Box>
            <Typography
              component="div"
              className="know-more-btn-content"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "none",md:"block" } }}
            >
              <HashLink smooth className="button w-button" to={"/#contact"}>
              Get quote!
              </HashLink>
            </Typography>
            <IconButton
              className="menu-btn"
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "block", xs: "block", md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
          </div>
         
        </AppBar>

        <Box component="nav">
          <Drawer
            anchor={"right"}
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </Styled>
  );
}

NavBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default NavBar;
