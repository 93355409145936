import styled from "styled-components/macro";
import { keyframes } from 'styled-components';
const moveInBottom =keyframes`
0% {
  transform: translateY(-100%);
}
100% {
  transform: translateX(0);
}
`
export default styled.div`
  
  .nav-bar {
    background-color: ${({ theme }) => theme?.colors?.["8"]};
    top: 0px;
    box-shadow: none;
  }
  .container{
    position: relative;
    width: 100%;
    max-width: 1300px;
    margin: auto;
    padding: 0 50px;
    @media (max-width: ${({ theme }) => theme?.deviceSize?.tablet}) {
      padding: 0 20px;
    }
  }
  .top-nav{
    animation: 0.7s ease-out 0s 1 ${moveInBottom};
    background-color: ${({ theme }) => theme?.colors?.["10"]};
    position: absolute;
    top:15px;
  }
  .scroll-nav{
    transform: translateX(0%);
    transition: 0.6s ease-in, background 0.6s ease-in;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
    background: ${({ theme }) => theme?.colors?.["1"]};
    @media (max-width: ${({ theme }) => theme?.deviceSize?.tablet}) {
      padding: 5px;
    }
  }
  .btn-enq{
    background-color:${({ theme }) => theme?.colors?.["1"]};
    color:${({ theme }) => theme?.colors?.["0"]};
  }
  .enq-div{
    border-left:2px solid grey;
    padding-left:10px;
  }

  .menu-drawer> .logo-icon {
    padding: 10px 15px;
    ul>li{
      color: background-color:${({ theme }) => theme?.colors?.["1"]};
    }
  }
  .menu-drawer{
    .nav-links{
      color: ${({ theme }) => theme?.colors?.["1"]} !important;
    }
  }
 
  .logo-icon {
    font-family: 'Philosopher', sans-serif !important;
    color: ${({ theme }) => theme?.colors?.["1"]};
    font-size: ${({ theme }) => theme?.fonts?.["4"]};
    font-weight: 500;
    text-align:left;
    max-width:33.33%;
    @media (max-width: ${({ theme }) => theme?.deviceSize?.tablet}) {
      max-width:100%;
    }
  }
.MuiDrawer-paper{
  width:80%
  padding:0 10px;
}
  .small-text {
    margin-top: -5px;
    color: ${({ theme }) => theme?.colors?.["2"]};
    font-size: ${({ theme }) => theme?.fonts?.["0"]};
  }
    .navbar-height {
      min-height: 80px;
      padding: 0 !important;
    }
   
  @media (max-width: ${({ theme }) => theme?.deviceSize?.tablet}) {
    .navbar-height {
      padding: 0 24px;
  }
   
  } 
  @media (max-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
    .navbar-height {
      padding: 5px 24px;
      min-height: 50px;
    }
   
  } 
  
  .nav-link-container {
    padding-left: 30px;
  }

  .nav-links {
    color: ${({ theme }) => theme?.colors?.["0"]};
    background-color: ${({ theme }) => theme?.colors?.["8"]};
    font-size: ${({ theme }) => theme?.fonts?.["1"]};
    text-decoration: auto;
    &:hover{
      color: ${({ theme }) => theme?.colors?.["2"]};
    }
  }
  .highlight-nav {
    width: 0%;
    height: 2px;
    background-color: ${({ theme }) => theme?.colors?.["2"]};
  }
  .menu-btn{
    background-color:${({ theme }) => theme?.colors?.["2"]};
    border-radius:2.5%;
    margin-right:0;
    &:hover{
      background-color:${({ theme }) => theme?.colors?.["2"]};
    }
    @media (max-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
      height: 85%
    }
    @media (min-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
      height: 50%;
    }
  }
  @media (min-width: ${({ theme }) => theme?.deviceSize?.tablet}) {
    .desk-nav-links{
      display: contents;
    }
  }
  
   @media (max-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
    img.logo{
      width:60px !important;
    }
  }
  img.logo{
    width: 80px;
    height: auto;
    display: block;
    margin: auto 0;
  }
  .desk-nav-links{
    width:60%;
  }
  .know-more-btn-content{
    text-align:right;
  }
 

`;
