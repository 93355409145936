import styled from "styled-components/macro";

export default styled.div`
  .MuiAvatar-root {
    background-color: ${({ avtarObjs, theme }) =>
      avtarObjs?.bgColor ? avtarObjs.bgColor : theme?.colors?.["2"]};
    width: ${({ avtarObjs }) => (avtarObjs?.width ? avtarObjs?.width : "50px")};
    height: ${({ avtarObjs }) =>
      avtarObjs?.width ? avtarObjs?.width : "50px"};
    margin:auto;
    border: ${({ avtarObjs }) =>
    avtarObjs?.border ? avtarObjs?.border : "none"};
    
  }
  > svg {
    font-size: ${({ iconSize }) => iconSize} !important;
    color: ${({ iconColor, theme }) =>
      iconColor ? iconColor : theme?.colors?.["0"]};
    display: inline-flex;
    font-weight:normal;
  }
  .MuiAvatar-root > svg {
    width: ${({ avtarObjs }) => {
      let sizeArr = avtarObjs?.width?.split("px")?.[0];
      sizeArr = parseInt(sizeArr)-parseInt(sizeArr)*40/100;
      return sizeArr ? `${sizeArr}px` : "80px";
    }};
    height: auto;
    color: ${({ iconColor, theme }) =>
      iconColor ? iconColor : theme?.colors?.["0"]};
    display: inline-flex;
  }

  
`;
