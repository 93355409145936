export const GetUpdates = {
  formId: "GetUpdates",
  formInp: [
    {
      inp: {
        type: "submit",
        label: "Autoload",
        name: "autoload",
        id: "autoload",
        service:{
          url:process.env.REACT_APP_API_URL+`api/get_data`,
          params:{type:'sv_updates'},
          method:'GET',
          Autoload:true,
        }
      },
      grid: 6,
    },
  ],
};
