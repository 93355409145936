import React from "react";
import Styled from "./styles";
import { Container } from "@mui/material";

const MainContainer = ({ children,padding='50px 0px 30px',bgColor=0 }) => {
  return (
    <Styled padding={padding} bgColor={bgColor} >
      <Container className="main-container" style={{padding:padding}}>{children}</Container>
    </Styled>
  );
};

export default MainContainer;
