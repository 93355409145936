import React from "react";
import Styled from "./styles";

export default function GoogleMaps() {
  return (
    <Styled>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.1297149182724!2d72.92379141421382!3d19.14579845475161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b9a5739bf1d3%3A0x70cebf1c74db762c!2sSwar%20Saptak%20Music%20Academy!5e0!3m2!1sen!2sus!4v1679553074613!5m2!1sen!2sus"
         
          allowfullscreen
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
    </Styled>
  );
}
