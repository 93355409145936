import React, { useEffect, useState } from "react";
import Styled from "./styles";
import { Grid } from "@mui/material";
import Admin from "../../Admin";
import TableDataGrid from "../../../common/DataGrid";
import { GetEnquiries } from "../../../common/formJson/Admin/Autoload/GetEnquiries";
import DynamicForm from "../../DynamicForm";
import { useSelector } from "react-redux";
const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "fullName",
    headerName: "Full name",
    width: 150,
  },

  {
    field: "phone",
    headerName: "Phone",
    width: 100,
  },
  {
    field: "message",
    headerName: "Message",
    width: 500,
    editable: true,
  },
];
const Enquiries = () => {
  const enquiries = useSelector(
    ({ dynamicForms }) => dynamicForms?.getEnquiries?.results?.data
  );

  //   const [showData,setShowData]=useState();
  //  useEffect(()=>{
  //    setShowData(enquiries);
  //  },[enquiries])
  return (
    <Admin title="Enquiries">
      <Styled>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <DynamicForm
              autoload={GetEnquiries?.formInp[0]?.inp?.service?.Autoload}
              formObj={GetEnquiries}
            />
            <TableDataGrid columns={columns || []} rows={enquiries || []} />
          </Grid>
        </Grid>
      </Styled>
    </Admin>
  );
};

export default Enquiries;
