import Styled from "./styles";
import { Grid } from "@mui/material";
import SubHeading from "../../common/SubHeadings";
import { useSelector } from "react-redux";
import IconComponent from "../../common/Icons";
const testimonies = [
  {
    icon: "GiGrandPiano",
    title: "Piano",
  },
  {
    icon: "MdPiano",
    title: "Key Board",
  },
  {
    icon: "FaGuitar",
    title: "Guitar",
  },
  {
    icon: "GiDrum",
    title: "Percussion",
  },
  {
    icon: "GiViolin",
    title: "Violin",
   },
  {
    icon: "GiMicrophone",
    title: "Vocals",
   },
   {
    icon: "FaMicrophoneAlt",
    title: "Koraoke",
   },
  {
    icon: "GiMusicalScore",
    title: "Music Theory",
   }
];
const Instruments = () => {
  
  const theme = useSelector(({ config }) => config?.theme);
  return (
    <Styled id="instruments">
      <Grid container item className="services-container" spacing={12}>
        <SubHeading  rhpart="Instruments" sColSwap={true} />
        {testimonies?.map((obj,index) => (
            <Grid  xs={12} sm={6} md={3} className="service-grids" >
              <div className="fun-facts-wrapper">
                <IconComponent
                  iconName={obj?.icon}
                  iconColor={theme?.colors?.["2"]}
                  avtarObjs={{ bgColor: theme?.colors?.["10"], width: "100px" }}
                />
                <div className="text-content">
                  <h1 className="fun-number">
                   {obj?.title}
                    <br></br>
                  </h1>
                  <p className="paragraph-white"> {obj?.desc}</p>
                </div>
              </div>
            </Grid>
          ))}
      </Grid>
    </Styled>
  );
};

export default Instruments;
