import React, { useEffect } from "react";
import Styled from "./styles";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import action from "../../../redux/action";
import { handlerResponseAlerts } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
const ButtonInput = ({
  label,
  formId,
  loaderLabel,
  variant = "outlined",
  service,
  editRecordId=false,
  ...props
}) => {
  const dispatch = useDispatch();
  const dynamicForms = useSelector(
    ({ dynamicForms }) => dynamicForms?.[formId]
  );
  const navigate = useNavigate();

  const triggerDServiceCall=()=>{
    if (service?.url) {
      const { url, method } = service;
      const formParams=service?.params||{};
      if(editRecordId){
        Object.assign(formParams,{id:editRecordId});
      }
      dispatch(
        action.dynamicFormServiceCall(
          dynamicForms?.inputValObjs,
          method,
          editRecordId?props?.editUrl:url,
          formId,
          formParams
        )
      );
    }
  }
  const triggerEServiceCall=()=>{
    if (service?.emailService) {
      dispatch(action.emailServiceCall(dynamicForms?.inputValObjs, formId));
    }
  }
  const onClickHandler = async (e) => {
    e.preventDefault();
     dispatch(action.setSubmitForm(formId));
    if (checkFormValidation()) {
      triggerEServiceCall();
      triggerDServiceCall();
    }
  };
useEffect(()=>{
  if(service?.Autoload){
   triggerDServiceCall();
  }
},[service?.Autoload])
  useEffect(() => {
    if (dynamicForms?.results?.status === 200) {
      if (service?.errorParams) {
        const resCode = handlerResponseAlerts(
          service?.errorParams,
          dynamicForms?.results
        );
        if (resCode === 3) {
          if (formId === "loginForm") {
            sessionStorage.setItem(
              "loginData",
              JSON.stringify(dynamicForms?.results?.data)
            );
          }
          if (service?.gotoLink) {
            navigate(service?.gotoLink);
          }
        }
      }
    }
  }, [dynamicForms?.results]);

  const checkFormValidation = () => {
    for (let key in dynamicForms?.validationPassed) {
      if (dynamicForms?.validationPassed?.[key] === false) {
        return false;
      }
    }
    return true;
  };
  return (
    <Styled>
      <Button
        variant={variant}
        {...props}
        onClick={onClickHandler}
        disabled={dynamicForms?.loadingData}
      >
        {dynamicForms?.loadingData ? <Loader /> : editRecordId?props?.editLabel:label}
      </Button>
    </Styled>
  );
};

export default ButtonInput;
