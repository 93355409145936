import React from "react";
import Alert from "@mui/material/Alert";
import Styled from "./styles";
import { useSelector } from "react-redux";
import { handlerResponseAlerts, getAlertData } from "../../utils/Utils";

const AlertComponent = ({ formId, errorParams }) => {
  const dfResponse = useSelector(
    ({ dynamicForms }) => dynamicForms?.[formId]?.results
  );

  const alertNumber = handlerResponseAlerts(errorParams, dfResponse);
  const { severity, message } = getAlertData(alertNumber);

  return (
    dfResponse?.status && (
      <Styled>
        <Alert severity={severity}>{message}</Alert>
      </Styled>
    )
  );
};

export default AlertComponent;
