import React from "react";
import Styled from "./styles";
import MainContainer from "../../common/MainContainer";
import SubHeading from "../../common/SubHeadings";
import GoogleMaps from "../../common/GoogleMaps";

const GetDirections = () => {
  return (
    <Styled  id="directions">
      <MainContainer padding="0px 37px 40px">
        <SubHeading fpart="Get" rhpart="directions" />
        <div className="paddingTop-20px"></div>
          <GoogleMaps />
      </MainContainer>
    </Styled>
  );
};

export default GetDirections;
