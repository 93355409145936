const initialState = {
    theme:null
  };
  
  const config = (state = initialState, action) => {
    switch (action.type) {
      case "UPDATE_THEME":
        return { ...state, theme: action.theme };
     
      default:
        return state;
    }
  };
  
  export default config;
  