import styled from "styled-components/macro";

export default styled.div`
  button.btn-std {
    &:hover{
      background-color:${({ theme }) => theme?.colors?.["2"]};
      border:2px solid ${({ theme }) => theme?.colors?.["1"]};
     
    }
  }
  button{
    border:2px solid ${({ theme }) => theme?.colors?.["0"]};
    transition: all 300ms ease;
    font-family: 'DM Mono', sans-serif;
    color: #fff;
    font-size: 16px;
    padding: 10px 30px;
    text-transform: capitalize;
    color:${({ theme }) => theme?.colors?.["0"]};
    background-color:${({ theme }) => theme?.colors?.["2"]};
  }
  button.btn-std1 {
    &:hover{
      background-color:${({ theme }) => theme?.colors?.["3"]};
    }
  }

`;
